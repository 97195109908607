import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import SubNav from 'components/sub-nav';
import i18n from 'utils/i18n';
import Layout from '../layout';
import ArticlePreview from './article-preview';
import Metatags from '../Metatags';

const styles = StyleSheet.create({
  sectionHeadline: {
    fontSize: '2em',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  breadcrumbs: {
    paddingInlineStart: '0px'
  },
  breadCrumbListItem: {
    display: 'inline',
    listStyleType: 'none'
  }
});

class Posts extends React.Component {
  render() {
    const { canonical, description, headline, locale, location, posts, title } = this.props;
    const firstPostWithImage = posts.find(post => !!post && !!post.node.heroImage);
    const defaultCardSrc = 'https://asset.lumosity.com/resources/static/press/logos/lumosity_logo.png';
    const src = firstPostWithImage ? firstPostWithImage.node.heroImage.openGraphCard.src : defaultCardSrc;
    const width = firstPostWithImage ? firstPostWithImage.node.heroImage.openGraphCard.width : 573;
    const height = firstPostWithImage ? firstPostWithImage.node.heroImage.openGraphCard.height : 148;
    return (
      <Layout locale={locale} location={location} footerOptions={{ renderLanguageLinks: false }}>
        <Metatags
          title={title}
          description={description}
          thumbnail={src}
          imageWidth={width}
          imageHeight={height}
          canonical={canonical}
        />
        <SubNav title={i18n.t('blog.subNav.title')} useH1={false} locale={locale} />
        <Container>
          <div>
            <h2 className={css(styles.sectionHeadline)}>{headline}</h2>
            <ul className="articleList">
              {posts.map(({ node }) => (
                <li key={node.slug}>
                  <ArticlePreview article={node} locale={locale} />
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </Layout>
    );
  }
}

Posts.propTypes = {
  canonical: PropTypes.string,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  locale: PropTypes.string,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

Posts.defaultProps = {
  canonical: '',
  locale: 'en',
  posts: []
};

export default Posts;

export const query = graphql`
  fragment BlogPost on ContentfulBlogPostEdge {
    node {
      node_locale
      title
      slug
      publishDate(formatString: "MMM D, YYYY")
      tags
      opengraphimage {
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
        twitterImage: file {
          url
        }
      }
      heroImage {
        fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid
        }
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
