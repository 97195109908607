import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Posts from 'components/blog/posts';

class BlogPostsByTagTemplate extends React.Component {
  getHeadline() {
    const {
      pageContext: { tag }
    } = this.props;
    return <h2>Recent News about {tag}</h2>;
  }

  render() {
    const {
      pageContext: { locale, tag, pagePath },
      location,
      data: {
        allContentfulBlogPost: { edges }
      }
    } = this.props;

    return (
      <Posts
        posts={edges}
        location={location}
        title={`Lumosity - Blog: ${tag} articles`}
        description={`The official Lumosity blog:  All articles about ${tag}`}
        headline={`Recent news about ${tag}`}
        locale={locale}
        canonical={pagePath}
        breadcrumbs={[
          { name: 'Home', url: `/${locale}` },
          { name: 'Recent News', url: `/${locale}/blog` },
          { name: tag, url: pagePath }
        ]}
      />
    );
  }
}

BlogPostsByTagTemplate.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string,
    tag: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export default BlogPostsByTagTemplate;

export const pageQuery = graphql`
  query BlogIndexByTagQuery($tag: String!) {
    allContentfulBlogPost(
      filter: { tags: { eq: $tag }, node_locale: { eq: "en" } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        ...BlogPost
      }
    }
  }
`;
