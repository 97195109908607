import React from 'react';
import { blue6, blue7, lumosWhite, fonts } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';

import PillGroupItem from './PillGroupItem';
import { centerVertical, mediaQuery, alignLeft, centerHorizontal } from '../../styles/helpers/layout';

const styles = StyleSheet.create({
  expandingBorder: {
    marginTop: 10,
    display: 'inline-block',
    [mediaQuery.minWidth.tablet]: {
      marginBottom: 10,
      ':after': {
        display: 'block',
        content: '""',
        borderBottom: `1px solid ${lumosWhite}`,
        transform: 'scaleX(0)',
        transition: 'transform 400ms ease-in-out',
        transformOrigin: '0% 50%'
      },
      ':hover': {
        ':after': {
          transitionDelay: '500ms',
          transform: 'scaleX(1)'
        }
      }
    }
  },
  container: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    ...centerVertical,
    ...centerHorizontal,
    flexDirection: 'column',
    display: 'inline-flex',
    [mediaQuery.minWidth.tablet]: {
      flexDirection: 'row'
    }
  },
  categoryLink: {
    ...fonts.p,
    textTransform: 'uppercase',
    color: lumosWhite,
    borderRadius: '5px',
    padding: '8px 12px',
    transition: 'background-color 0.2s linear',
    ':hover': {
      textDecoration: 'none',
      backgroundColor: blue7,
    },
  },
  categoryLinkActive: {
    backgroundColor: blue6,
  },
});

export default function PillGroup({ links }) {
  return (
    <div className={css(styles.expandingBorder)}>
      <ul className={css(styles.container)}>
        {links.map((item) => (
          <PillGroupItem to={item.url}
            key={item.name}
            className={css(styles.categoryLink)}
            activeClassName={css(styles.categoryLinkActive)}
          >
            {item.name}
          </PillGroupItem>
        ))}
      </ul>
    </div>
  );
};
