import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { StyleSheet, css } from 'aphrodite'
import { black } from '@lumoslabs/react_lux'

const styles = StyleSheet.create({
  previewTitle: {
    fontSize: '1.6em',
    lineHeight: '1.4em',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    fontWeight: '500'
  },

  link: {
    textDecoration: 'none',
    color: black
  },

  pubDate: {
    color: '#4DBCB6',
    fontSize: '1.2em',
    lineHeight: '1.5em',
    marginBlockStart: '0.75em',
    marginBlockEnd: '0.75em',
    fontWeight: 'normal'
  },

  tag: {
    color: '#A0A0A0',
    textDecoration: 'none',
    display: 'inline-block',
    padding: '.33333rem .5rem',
    lineHeight: 1,
    borderRadius: '2px',
    border: '1px solid #A0A0A0',
    marginRight: '.5em',
  },
  description: {
    color: '#555555'
  }
})

export default ({ article, locale }) => (
  <div>
    <Link className={css(styles.link)} to={`/${locale}/blog/${article.slug}`}>
      <h5 className={css(styles.pubDate)}>
        {article.publishDate}
      </h5>
      <h3 className={css(styles.previewTitle)}>
      {article.title}
      </h3>
      <p className={css(styles.description)}>{article.description.description}</p>
    </Link>
  </div>
)
