import React from 'react';
import PropTypes from 'prop-types';
import { lumosWhite, blue4, fonts } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import { centerVertical, alignLeft, centerHorizontal, mediaQuery } from '../../styles/helpers/layout';

import PillGroup from './PillGroup';

const styles = StyleSheet.create({
  section: {
    overflow: 'auto',
    backgroundColor: blue4,
    color: lumosWhite
  },
  container: {
    ...centerVertical,
    ...centerHorizontal,
    paddingTop: 20,
    paddingBottom: 20
  },
  row: {
    width: '100%',
    ...centerVertical
  },
  underlineOnMobile: {
    textDecoration: 'underline',
    [mediaQuery.minWidth.tablet]: {
      textDecoration: 'none'
    }
  },
  header: {
    ...fonts.h3,
    marginLeft: 0,
    color: lumosWhite,
    textAlign: 'center',
    [mediaQuery.minWidth.tablet]: {
      marginLeft: 12,
      textAlign: 'left'
    }
  },
  headerLink: {
    color: lumosWhite
  },
  linksContainer: {
    textAlign: 'center',
    ...centerHorizontal,
    [mediaQuery.minWidth.tablet]: {
      ...alignLeft
    }
  }
});

function SubNav({ title, links, useH1, to }) {
  const Tag = useH1 ? 'h1' : 'div';
  return (
    <section className={css(styles.section)}>
      <Container fluid className={css(styles.container)}>
        <Row fluid="true" className={css(styles.row)}>
          <Col xs="12">
            <Tag className={css(styles.header, links.length > 0 && styles.underlineOnMobile)}>
              {to ? (
                <Link className={css(styles.headerLink)} to={to}>
                  {title}
                </Link>
              ) : (
                title
              )}
            </Tag>
          </Col>
          <Col xs="12" className={css(styles.linksContainer)}>
            {links.length > 0 && <PillGroup links={links} />}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

SubNav.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  useH1: PropTypes.bool,
  to: PropTypes.string
};

SubNav.defaultProps = {
  links: [],
  useH1: true,
  to: null
};

export default SubNav;
