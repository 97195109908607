import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Adds capability to partial match paths for 'active' highlighting
// e.g., activeClassName with an href of /blog will be active both on /blog
// and /blog/example-post
// https://github.com/gatsbyjs/gatsby/issues/3238#issuecomment-413314187

export default function PillGroupItem({ exact, className, activeClassName, to, children }) {
  return (
    <Link
      to={to}
      getProps={({ isCurrent, isPartiallyCurrent }) => ({
        className: [
          className,
          (exact && isCurrent) ? activeClassName : '',
          (!exact && isPartiallyCurrent) ? activeClassName : '',
        ].join(' ').trim(),
      })}
    >
      {children}
    </Link>
  );
}

PillGroupItem.propTypes = {
  exact: PropTypes.bool,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

PillGroupItem.defaultProps = {
  exact: false,
  activeClassName: 'active',
  className: '',
};
